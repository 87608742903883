import React from 'react'
import PropTypes from 'prop-types'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

// utils
import {SLACK_ENDPOINT, SLACK_DIRECT_ACCOUNT_PAYLOAD, SEGMENT_IDENTIFY, SEGMENT_EVENT} from '../../utils/constants'

const timeline = [
    '',
    'Less than a month away',
    '3 months away',
    '3 - 6 months away',
    '6 - 9 months away',
    '9+ months away',
    'Not sure'
]

const exam = [
    '',
    'IGCSE',
    'GCSE',
    'O Level'
]

const SUCCESS_TICK = (measurements=48) => <svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'
    width={measurements} height={measurements}
    viewBox='0 0 48 48'
    style=' fill:#000000;'><linearGradient id='I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1' x1='9.858' x2='38.142' y1='9.858' y2='38.142' gradientUnits='userSpaceOnUse'><stop offset='0' stopColor='#21ad64'></stop><stop offset='1' stopColor='#088242'></stop></linearGradient><path fill='url(#I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1)' d='M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z'></path><path d='M32.172,16.172L22,26.344l-5.172-5.172c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l8,8c0.781,0.781,2.047,0.781,2.828,0l13-13c0.781-0.781,0.781-2.047,0-2.828L35,16.172	C34.219,15.391,32.953,15.391,32.172,16.172z' opacity='.05'></path><path d='M20.939,33.061l-8-8c-0.586-0.586-0.586-1.536,0-2.121l1.414-1.414c0.586-0.586,1.536-0.586,2.121,0	L22,27.051l10.525-10.525c0.586-0.586,1.536-0.586,2.121,0l1.414,1.414c0.586,0.586,0.586,1.536,0,2.121l-13,13	C22.475,33.646,21.525,33.646,20.939,33.061z' opacity='.07'></path><path fill='#fff' d='M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0	L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414l-13,13	C22.317,33.098,21.683,33.098,21.293,32.707z'></path>
</svg>

const successMessage = 'We\'ll create your account within 24 hours. Contact us at hello@quicksense.org, if you have any questions or do not hear from us.'
const errorMessage = 'We had trouble processing your request. Feel free to try again. If this keeps happening, email us at hello@quicksense.org'

const formSchema = Yup.object().shape({
    parentName: Yup.string()
        .required('Enter your name so we know who you are')
        .nullable(),

    parentEmail: Yup.string()
        .email('Check your email address. Did you add @?')
        .nullable()
        .required('We need this to inform you when your QuickSense account is ready'),
        
    childEmail: Yup.string()
        .email('Check your email address. Did you add @?')
        .required(`We need this so we can create an account for your child. If you want them to use your email address enter that instead.`),
    
    childName: Yup.string()
        .required('We need this identify who is enrolled in our premium cohort, and to provide access to our Discord premium channel.'),

    exam: Yup.string()
        .required('We need this so we know which stream to enrol them for our pre-exam sessions'),

    timeline: Yup.string()
        .required('This helps us plan for our pre-exam tutoring sessions')
})

const requiredAsterix = <span className='text-danger pr-1'>*</span>

class DirectForm extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            showNotification: false,
            notificationMessage: null,
            errorMessage: false
        }
    }

    submitForm(values){
        /** SLACK WEBHOOKS: https://stackoverflow.com/questions/45752537/slack-incoming-webhook-request-header-field-content-type-is-not-allowed-by-acce **/
        /** Tldr: Make sure there's no header as it breaks via JS. Alternatives is using header: application/x-www-form-urlencoded **/
        /** Slack payload will break unless it is wrapped within another object **/

        SEGMENT_IDENTIFY(values)
        SEGMENT_EVENT(values, 'website_post_purchase_form_sent')

        fetch(SLACK_ENDPOINT, {
            method: 'POST',
            body: JSON.stringify(SLACK_DIRECT_ACCOUNT_PAYLOAD(values))
        }).then(response => {
            if (!response.ok) { 
                this.setState({showNotification: true, notificationMessage: errorMessage, errorMessage: true})
                setTimeout(() => this.setState({showNotification: false}), 9000)
            }
            else {
                this.setState({showNotification: true, notificationMessage: successMessage})
                setTimeout(() => this.setState({showNotification: false}), 9000)
            }
        }).catch(console.error)
    }

    render(){
        const FORM_VALUES = {
            parentName: this.props.parentName,
            parentEmail: this.props.parentEmail,
            childName: '',
            childEmail:'',
            exam: '',
            timeline: '',
            purchaseId: this.props.checkoutId,
            customerId: this.props.customerId
        }
        return  <>
            <Formik initialValues={FORM_VALUES} validationSchema={formSchema} onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                    this.submitForm(values)
                    setSubmitting(false)
                    resetForm()
                }, 400)
            }}
            >
                {({ errors,touched, isSubmitting }) => (
                    <Form className='col-12 bg-white shadow py-3 py-sm-4 px-sm-4 rounded'>
                        <h2 className='h3'>Your details</h2>
                        <div className='form-group'>
                            <label htmlFor='parentName'>{requiredAsterix}Name</label>
                            <Field className={`form-control ${ errors.parentName && touched.parentName ? 'is-invalid' : ''} ${!errors.parentName && touched.parentName ? 'is-valid' : ''}`}  type='text' name='parentName' disabled={isSubmitting}/>
                            {errors.parentName && touched.parentName && (<small className='form-text text-danger'>{errors.parentName}</small>)}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='parentEmail'>{requiredAsterix}Email</label>
                            <Field className={`form-control ${ errors.parentEmail && touched.parentEmail ? 'is-invalid' : ''} ${!errors.parentEmail && touched.parentEmail ? 'is-valid' : ''}`} type='email' name='parentEmail' disabled={isSubmitting}/>
                            {errors.parentEmail && touched.parentEmail && (<small className='form-text text-danger'>{errors.parentEmail}</small>)}
                        </div>
                        <hr/>
                        <h2 className='h3'>Your child</h2>
                        <div className='form-group'>
                            <label htmlFor='childName'>{requiredAsterix}{`Child's name`}</label>
                            <Field className={`form-control ${ errors.childName && touched.childName ? 'is-invalid' : ''} ${!errors.childName && touched.childName ? 'is-valid' : ''}`}  type='text' name='childName' disabled={isSubmitting}/>
                            {errors.childName && touched.childName && (<small className='form-text text-danger'>{errors.childName}</small>)}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='childEmail'>{requiredAsterix}{`Child's email`}</label>
                            <Field className={`form-control ${ errors.childEmail && touched.childEmail ? 'is-invalid' : ''} ${!errors.childEmail && touched.childEmail ? 'is-valid' : ''}`}  type='text' name='childEmail' disabled={isSubmitting}/>
                            {errors.childEmail && touched.childEmail && (<small className='form-text text-danger'>{errors.childEmail}</small>)}
                            {!errors.childEmail && <small>This will be the email they use to log into the platform.</small>}
                        </div>
                        <hr />
                        <h2 className='h3'>Exam Details</h2>
                        <div className='form-group'>
                            <label htmlFor='exam'>{requiredAsterix}{`What exam are they sitting?`}</label>
                            <Field className={`form-control ${ errors.exam && touched.exam ? 'is-invalid' : ''} ${!errors.exam && touched.exam ? 'is-valid' : ''}`}  as='select' name='exam' disabled={isSubmitting}>
                                {
                                    exam.map((label, index) => (
                                        <option value={label} key={index} disabled={index == 0 && true} selected={index == 0 && true} hidden={index == 0 && true}>{label}</option>
                                    ))
                                }
                            </Field>
                            {errors.exam && touched.exam && (<small className='form-text text-danger'>{errors.exam}</small>)}
                        </div>
                        <div className='form-group'>
                            <label>{requiredAsterix}{`When is the exam?`} </label>
                            <Field name='timeline' as='select' className={`form-control ${ errors.timeline && touched.timeline ? 'is-invalid' : ''}  ${!errors.timeline && touched.timeline ? 'is-valid' : ''}`}>
                                {
                                    timeline.map((label,index) => (
                                        <option value={label} key={index} disabled={index == 0 && true} selected={index == 0 && true} hidden={index == 0 && true}>{label}</option>
                                    ))
                                }
                            </Field>
                            {!errors.timeline && !touched.timeline && <em className='text-small'>This helps us plan for our pre-exam tutoring sessions</em>}
                            {errors.timeline && touched.timeline && (<small className='form-text text-danger'>{errors.timeline}</small>)}
                        </div>
                        <button className='btn btn-primary mb-3 px-5' type='submit' disabled={isSubmitting}>
                            Submit
                        </button>
                        {
                            this.state.showNotification &&
                              <div className={`'alert rounded p-3 ${this.state.errorMessage ? 'alert-danger' : 'alert-success'}`}>
                                  <strong className='d-flex align-items-center'>{this.state.errorMessage ? null : SUCCESS_TICK(24)}{this.state.errorMessage ? 'Error' : 'Success'}</strong>
                                  <p className='m-0'><small>{this.state.notificationMessage}</small></p> 
                              </div> 
                        }
                    </Form>
                )}   
            </Formik>

        </>
    }
}

export default DirectForm

DirectForm.propTypes = {
    parentName: PropTypes.string,
    parentEmail: PropTypes.string,
    checkoutId: PropTypes.string,
    customerId: PropTypes.string,
    plan: PropTypes.string
}
