import React from 'react'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import SEO from '../components/SEO/seo'
import Button from '../components/Button/button'
import Image from '../components/Image/image'
import Layout from '../components/Layout/layout'
import Section from '../components/Section/section'

//content
import {HERO_CONTENT, FOUR_BENEFITS } from '../utils/pages/qsParentsPageData'

const STAR_SVG = <svg width='24' height='24' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M24.913 5.18603L30.391 17.474L43.769 18.887C44.63 18.978 44.976 20.045 44.333 20.624L34.34 29.629L37.131 42.79C37.311 43.637 36.403 44.296 35.653 43.864L24 37.141L12.347 43.863C11.597 44.295 10.69 43.636 10.869 42.789L13.66 29.628L3.66705 20.623C3.02405 20.044 3.37105 18.977 4.23105 18.886L17.609 17.473L23.087 5.18503C23.439 4.39503 24.561 4.39503 24.913 5.18603Z' fill='url(#paint0_linear)'/>
    <defs>
        <linearGradient id='paint0_linear' x1='9.00905' y1='6.36003' x2='38.092' y2='45.266' gradientUnits='userSpaceOnUse'>
            <stop stopColor='#FEB525'/>
            <stop offset='1' stopColor='#FEB705'/>
        </linearGradient>
    </defs>
</svg>

class DirectParentsPage extends React.Component {

    __renderHeroSection() { 
        return <div>
            <div className={'d-flex align-items-center heroBg'} data-sal='fade' data-sal-duration='800' data-sal-delay='1200' data-sal-easing='ease-out-quad'>
                <Container className={'heroContainer text-center justify-content-center'}>
                    <Row>
                        <Col className={'heroSection col-10'} as='section'>
                            <h1 data-sal='slide-up' data-sal-duration='2000' data-sal-delay='300' data-sal-easing='ease-out-quart' className={'heroTitle'}>{HERO_CONTENT.title}</h1>
                            {
                                HERO_CONTENT.description.map((content, index) => (
                                    <p key={index} data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart' className={'heroDescription'}>{content}</p>
                                ))
                            }
                            <div className='d-flex flex-row flex-column-md align-items-center justify-content-center align-items-sm-start justify-content-sm-start' data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>
                                {/* WRAPPER FOR ANIMATION */}
                                <Button classes={'px-5 mt-3'} primary>BUY NOW</Button>
                                <Button secondary isInternal classes={'d-none d-sm-inline-block ml-3 mt-3'} event={'website_learn_more_button_clicked'} eventValues={{location: 'Hero Section', page: 'Home'}} to={'how-it-works'} content={'LEARN MORE'} />                              
                            </div>
                            <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart' className={'heroDescription pt-3 text-small d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center '}>
                                <span> &nbsp;<span style={{marginTop: '-4px'}}>{STAR_SVG}</span> 4.81/5 stars (161 reviews)</span>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    __renderBenefits() {
        return <Section background={FOUR_BENEFITS.background} center>
            <Col className='col-lg-10 text-center' as='section' lg={10}>
                <strong className='text-secondary  d-block text-uppercase'>{FOUR_BENEFITS.superTitle}</strong>
                <h2 className={`h1 mb-4 text-center `}>{FOUR_BENEFITS.title}</h2>
                <p>{FOUR_BENEFITS.description}</p>
                <Row>
                    {
                        FOUR_BENEFITS.benefits.map((benefit, index) => (
                            <Col as={'figure'} lg={3} className='col-6 col-lg-3 mb-0' key={index}>
                                <Image src={benefit.imageSrc} srcWebp={benefit.imageWebpSrc} alt={benefit.title}/>
                                <figcaption className='h3 mt-3'>{benefit.title}</figcaption>
                            </Col>
                        ))
                    }
                </Row>
            </Col>
        </Section>
    }

    render() {
        return (
            <>
                <SEO title={'Parents'}/>
                <Layout type='floating' classes='fw-landing-page text-center text-sm-left' focusedMode focusedModeURL={'/parents/'}>
                    {this.__renderHeroSection()}
                    {this.__renderBenefits()}
                </Layout>
            </>
        )
    }
}

export default DirectParentsPage