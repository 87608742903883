export const SLACK_ENDPOINT = 'https://hooks.slack.com/services/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'

export const SIGN_UP_LINK = 'https://app.quicksense.org/signup'

export const STRIPE_ENDPOINT = 'https://api.stripe.com/v1/'

export const STRIPE_CHECKOUT_SLUG = 'checkout/sessions/'

export const STRIPE_CUSTOMER_SLUG = 'customers/'

export const STRIPE_READ_KEY = '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'

export const ZAPIER_DIRECT_ENDPOINT = 'https://hooks.slack.com/services/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'

export const NOTES_SLUG = 'resources'

export const BRAND_LOGO = "<svg version='1.1' class='website-logo' viewBox='0 0 56 56' height='32px' width='32px' xmlns='http://www.w3.org/2000/svg'><g fill='none' fill-rule='evenodd' stroke-width='1' stroke='none'><g id='QuickSense-Logo'><circle cx='28' cy='28' r='28' fill='#2A285C' class='qs-svg__oval' fill-rule='nonzero'></circle><circle cx='9.5' cy='35.5' r='2.5' fill='#2A285C' class='qs-svg__oval' fill-rule='nonzero' stroke='#FFFFFF' stroke-width='2'></circle><circle cx='23.5' cy='25.5' r='2.5' fill='#2A285C' class='qs-svg__oval' fill-rule='nonzero' stroke='#FFFFFF' stroke-width='2'></circle><circle cx='32.5' cy='35.5' r='2.5' fill='#2A285C' class='qs-svg__oval' fill-rule='nonzero' stroke='#FFFFFF' stroke-width='2'></circle><circle cx='46.5' cy='21.5' r='2.5' fill='#2A285C' class='qs-svg__oval' fill-rule='nonzero' stroke='#FFFFFF' stroke-width='2'></circle><path d='M11,34 L21.8333333,27.125'  stroke='#FFFFFF' stroke-width='2'></path><path d='M25,27 L31,33'  stroke='#FFFFFF' stroke-width='2'></path><path d='M34,34 L45,23'  stroke='#FFFFFF' stroke-width='2'></path></g></g></svg>"

export const IG_PRICING_DATA = [
    {
        plan: 'Free',
        price: 0,
        benefits: [
            'Access to 50 questions',
            'Limited tracking'
        ]
    },
    {
        plan: 'Premium',
        price: 297,
        benefits: [
            'A/7+ grade guarantee',
            'Access to over 2,000 questions',
            'Grade tracking & analytics',
            'Exam tips, videos and notes'
        ]
    }
]

export const SLACK_PAYLOAD = values => {
    return {
        'text': 'New message',
        'blocks': [
            {
                'type': 'header',
                'text': {
                    'type': 'plain_text',
                    'text': 'QuickSense Website Enquiry'
                }
            },
            {
                'type': 'section',
                'text': {
                    'type': 'mrkdwn',
                    'text': 'Details'

                }
            },
            {
                'type': 'section',
                'fields':[
                    {
                        'type': 'mrkdwn',
                        'text': `•*Name*: ${values.name} \n\n•*Email*: ${values.email}`
                    }
                ]
            },
            {
                'type': 'divider'
            },
            {
                'type': 'section',
                'text': {
                    'type': 'mrkdwn',
                    'text': `*Message*\n\n${values.message}`

                }
            }
        ]

    }
}

export const SLACK_BOOKING_PAYLOAD = values => {
    return {
        'text': 'New message',
        'blocks': [
            {
                'type': 'header',
                'text': {
                    'type': 'plain_text',
                    'text': 'Parents Booking Enquiry'
                }
            },
            {
                'type': 'section',
                'text': {
                    'type': 'mrkdwn',
                    'text': 'Details'

                }
            },
            {
                'type': 'section',
                'fields':[
                    {
                        'type': 'mrkdwn',
                        'text': `•*Name*: ${values.name} \n\n•*Email*: ${values.email}`
                    }
                ]
            },
            {
                'type': 'divider'
            },
            {
                'type': 'section',
                'text': {
                    'type': 'mrkdwn',
                    'text': `*Message*\n\n${values.message}`

                }
            }
        ]
    }
}

export const SLACK_DIRECT_ACCOUNT_PAYLOAD = values => {
    return {
        'text': 'New message',
        'blocks': [
            {
                'type': 'header',
                'text': {
                    'type': 'plain_text',
                    'text': 'Account Creation'
                }
            },
            {
                'type': 'section',
                'text': {
                    'type': 'mrkdwn',
                    'text': 'Details'

                }
            },
            {
                'type': 'section',
                'fields':[
                    {
                        'type': 'mrkdwn',
                        'text': `•*Parent Name*: ${values.parentName} \n\n•*Parent Email*: ${values.parentEmail} \n\n•*Child Name*: ${values.childName} \n\n•*Child Email*: ${values.childEmail} \n\n•*Exam*: ${values.exam} \n\n•*Timeline*: ${values.timeline} \n\n•*Checkout ID*: ${values.purchaseId} \n\n•*Customer ID*: ${values.customerId}`
                    }
                ]
            }
        ]
    }
}

export const SEGMENT_IDENTIFY = values => {
    return window.analytics.identify({
        name: values.name,
        email: values.email
    })
}

export const SEGMENT_EVENT = (values, event) => {
    return window.analytics.track(event, {
        name: values.name, 
        email: values.email
    })
}
