import React from 'react'

// Bootstrap & Layout
import Col from 'react-bootstrap/Col'
import Layout from '../components/Layout/layout'
import Section from '../components/Section/section'

//Components
import SEO from '../components/SEO/seo'
import Form from '../components/Forms/direct'

import {STRIPE_CHECKOUT_SLUG, STRIPE_CUSTOMER_SLUG, STRIPE_ENDPOINT, STRIPE_READ_KEY} from '../utils/constants'

const SUCCESS_TICK = (measurements=48) => <svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'
    width={measurements} height={measurements}
    viewBox='0 0 48 48'
    style=' fill:#000000;'><linearGradient id='I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1' x1='9.858' x2='38.142' y1='9.858' y2='38.142' gradientUnits='userSpaceOnUse'><stop offset='0' stopColor='#21ad64'></stop><stop offset='1' stopColor='#088242'></stop></linearGradient><path fill='url(#I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1)' d='M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z'></path><path d='M32.172,16.172L22,26.344l-5.172-5.172c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l8,8c0.781,0.781,2.047,0.781,2.828,0l13-13c0.781-0.781,0.781-2.047,0-2.828L35,16.172	C34.219,15.391,32.953,15.391,32.172,16.172z' opacity='.05'></path><path d='M20.939,33.061l-8-8c-0.586-0.586-0.586-1.536,0-2.121l1.414-1.414c0.586-0.586,1.536-0.586,2.121,0	L22,27.051l10.525-10.525c0.586-0.586,1.536-0.586,2.121,0l1.414,1.414c0.586,0.586,0.586,1.536,0,2.121l-13,13	C22.475,33.646,21.525,33.646,20.939,33.061z' opacity='.07'></path><path fill='#fff' d='M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0	L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414l-13,13	C22.317,33.098,21.683,33.098,21.293,32.707z'></path>
</svg>

class SuccessPage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            valid: false,
            error: false,
            checkout: null,
            customer: null,
            stripeDirect: null
        }
    }

    componentDidMount(){
        let getURL = new URL(window.location.href)
        let getSearchParams = new URLSearchParams(getURL.search)
        let getStripeId = getSearchParams.getAll('id')
        let getStripeCustomer = getSearchParams.getAll('customer')

        if (getSearchParams.has('fromStripe') && getSearchParams.getAll('fromStripe')=='true'){
            this.setState({loading: false, valid: true, error: false, stripeDirect: true})
        }

        if (getStripeId.length > 0 && getSearchParams.has('customer')) {
            this.__validateInfo(getStripeCustomer, STRIPE_CUSTOMER_SLUG, 'customer')
        }

        if (getStripeId.length > 0 && getSearchParams.has('id')) {
            this.__validateInfo(getStripeId, STRIPE_CHECKOUT_SLUG, 'checkout')
        }
    }

    __validateInfo(requestId, slug, state){
        this.setState({loading: true, valid: true})

        fetch(`${STRIPE_ENDPOINT}${slug}${requestId}`, {
            method: 'GET',
            headers: {'Authorization' : `Bearer ${STRIPE_READ_KEY}`}
        })
            .then(response => {
                if (!response.ok) this.setState({error:true})
                return response.body
            })
            .then(rb => {
                const reader = rb.getReader()
                return new ReadableStream({
                    start(controller) {
                        // The following function handles each data chunk
                        function push() {
                            // "done" is a Boolean and value a "Uint8Array"
                            reader.read().then( ({done, value}) => {
                                // If there is no more data to read
                                if (done) {
                                    controller.close()
                                    return
                                }
                                // Get the data and send it to the browser via the controller
                                controller.enqueue(value)
                                // Check chunks by logging to the console
                                push()
                            })
                        }
    
                        push()
                    }
                })
            })
            .then(stream => {
            // Respond with our stream
                return new Response(stream, { headers: { 'Content-Type': 'text/json' } }).json()
            })
            .then(result => {
                this.setState({ loading: false })
                state == 'customer' ? this.setState({ customer: result }) : this.setState({ checkout: result })
            })
            .catch(error => {
                console.error('Error:', error)
                this.setState({error: true})
            })
    }

    render(){
        return <>
            <SEO title={'Success'}/>
            <Layout>
                <Section center>
                    <Col lg={10} className='col-12 col-lg-10' as='section'>
                        {
                            this.state.loading && <div className='d-flex flex-column align-items-center'>
                                <div className='spinner-border' role='status'>
                                    <span className='sr-only'>Loading...</span>
                                </div>
                                <strong>Loading...</strong>
                            </div>
                        }
                        {
                            (!this.state.valid || this.state.error) && <div className='alert alert-danger' role='alert'>
                                <strong>Error: You do not have the necessary permissions to access this page. </strong>
                                <br/><br/>
                               Please contact us or try again. 
                            </div>
                        }
                        {
                            (!this.state.loading && !this.state.error && this.state.valid)  &&
                            <>
                                <h1 className='mt-5 mt-sm-0'>Hi {this.state.customer ? this.state.customer.name : 'there,'}</h1>
                                { this.state.stripeDirect && <p>{SUCCESS_TICK(24)}<strong>Your payment was successful!</strong></p>}
                                { this.state.stripeDirect && <p><small><em>If you want to fill this in later, {`we've`} emailed you a link to access this page</em></small></p>}
                                <hr/>
                                <h2>Next steps</h2>
                                <p> Fill in the form below. We will provide your child access to QuickSense and our discourd channel within 24 hours.</p>
                                <p><small><em>(Note: May take up to 48 hours over the weekend)</em></small></p>
                                <p><small><em>If you run into any issues, contact us directly at hello@quicksense.org</em></small></p>
                                <Form 
                                    parentName={this.state.customer && this.state.customer.name}
                                    parentEmail={this.state.customer && this.state.customer.email}
                                    customerId={this.state.customer && this.state.customer.id} 
                                    checkoutId={this.state.checkout && this.state.checkout.id } />
                            </>
                        }
                    </Col>
                </Section>
            </Layout>
        </>
    }

}

export default SuccessPage