import React, {createRef} from 'react' 
import PropTypes from 'prop-types'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

//Components
import SEO from '../components/SEO/seo'
import Contact from '../components/Forms/contact'
import Image from '../components/Image/image'
import Button from '../components/Button/button'
import Layout from '../components/Layout/layout'
import Section from '../components/Section/section'
import Testimonials from '../components/Testimonials/testimonials'

//content
import { PARENT_TESTIMONIALS, RP_TESTIMONIAL } from '../utils/content/rpTestimonials'
import { HERO_CONTENT, FOUR_BENEFITS, YODA_BENEFIT, CONQUER_WEAKNESS_BENEFIT, FORGETTING_CURVE_BENEFIT, ELEPHANT_EATING_BENEFIT, GET_A_WITHOUT_QUICKSENSE, EVERYTHING_CHILD_NEEDS, HOW_IT_WORKS_VIDEO, TUTORING_COMPARISON, PRICING_DATA, PRICING_FEATURES, SECURE_GRADE, PROGRAM_STOP_CHARGE, OUR_TEAM, FOUNDER_MESSAGE, KEY_PEOPLE, FAQ_SECTION } from '../utils/pages/qsParentsPageData'

const STAR_SVG = <svg width='24' height='24' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M24.913 5.18603L30.391 17.474L43.769 18.887C44.63 18.978 44.976 20.045 44.333 20.624L34.34 29.629L37.131 42.79C37.311 43.637 36.403 44.296 35.653 43.864L24 37.141L12.347 43.863C11.597 44.295 10.69 43.636 10.869 42.789L13.66 29.628L3.66705 20.623C3.02405 20.044 3.37105 18.977 4.23105 18.886L17.609 17.473L23.087 5.18503C23.439 4.39503 24.561 4.39503 24.913 5.18603Z' fill='url(#paint0_linear)'/>
    <defs>
        <linearGradient id='paint0_linear' x1='9.00905' y1='6.36003' x2='38.092' y2='45.266' gradientUnits='userSpaceOnUse'>
            <stop stopColor='#FEB525'/>
            <stop offset='1' stopColor='#FEB705'/>
        </linearGradient>
    </defs>
</svg>

const CHEVRON_RIGHT = <svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='16' height='16' viewBox='0 0 48 48' style=' fill:#2a285c;'><path d='M38.561,25.061c0.586-0.586,0.586-1.535,0-2.121l-20.5-20.5c-0.586-0.586-1.535-0.586-2.121,0l-6.5,6.5 c-0.586,0.586-0.586,1.535,0,2.121L22.379,24L9.439,36.939c-0.586,0.586-0.586,1.535,0,2.121l6.5,6.5 C16.232,45.854,16.616,46,17,46s0.768-0.146,1.061-0.439L38.561,25.061z'></path></svg>

const CHEVRON_DOWN = <svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='16' height='16' viewBox='0 0 48 48' style=' fill:#2a285c;'><path d='M39.061,9.439c-0.586-0.586-1.535-0.586-2.121,0L24,22.379L11.061,9.439c-0.586-0.586-1.535-0.586-2.121,0l-6.5,6.5 c-0.586,0.586-0.586,1.535,0,2.121l20.5,20.5C23.232,38.854,23.616,39,24,39s0.768-0.146,1.061-0.439l20.5-20.5 c0.586-0.586,0.586-1.535,0-2.121L39.061,9.439z'></path></svg>

const PREMIUM = <span className='qs-premium-badge mr-2'>PLUS</span>

const HOME_BUY_NOW_EVENT='website_buy_now_button_clicked'

const HOME_BUY_NOW_EVENT_VALUE={location: 'Hero Section', page: 'Parents Direct Page'}

const HOME_LEARN_MORE_EVENT='website_learn_more_button_clicked'

const HOME_LEARN_MORE_EVENT_VALUE={location: 'Hero Section', page: 'Parents Direct Page'}

const Pricing = ({data}) => {
    return <Row className='shadow py-4 bg-white rounded mb-3'>
        {
            data.map((price, index) => (
                <Col as='figure' className='col-6 d-flex flex-column align-items-center' key={index}>
                    <p>{price.name} {price.plan === 1 && PREMIUM}</p>
                    <figcaption className='h1 mb-0'>£<span >{price.price}</span></figcaption>
                    <p className='text-center'>One-off payment <br/> 2-years access</p>
                    <Button href={price.link} primary event={price.event} eventValues={price.eventValues} className='mt-auto'>BUY NOW</Button>
                </Col>
            ))
        }
    </Row>
}

const PricingFeatures = ({data}) => {
    return <div>
        <h3 className='p-3 bg-primary text-white text-left m-0'>FEATURES INCLUDED</h3>
        <ul className='text-left '>
            {
                data.map((feature, index) => (
                    <li key={index} className='my-3'>{index <2 && PREMIUM}{feature}</li>
                ))
            }
        </ul>
    </div>
}

const FAQ = ({content}) => {
    const [showResults, setShowResults] = React.useState(false)
    const onClick = () => setShowResults(!showResults ? true : false)
    return  <div className='qs-faq'>
        <p className='qs-faq__title' onClick={onClick}>{showResults ? CHEVRON_DOWN : CHEVRON_RIGHT}&nbsp;{content.title}</p>
        { showResults && <p className='qs-faq__description' dangerouslySetInnerHTML={{__html: content.description}}/>}
        { showResults && <hr/>}
    </div>
}

const PageSection = ({content, showLoomVideo, children, refProp}) => {
    return <Section background={content.background} spacing={'py-3'} center refProp={refProp}>  
        <Col lg={10} className='col-12 col-lg-10' as='section'>
            <strong className='text-secondary text-center d-block text-uppercase'>{content.superTitle}</strong>
            <h2 className={`h1 mb-4 text-center `}>{content.title}</h2>
            {
                content.content.map((content, index) => (
                    content.type == 'heading' ?

                        <h3 key={index} className={`${content.className ? content.className : 'h2' } mb-3`}>{content.description}</h3>:

                        content.type == 'paragraph' ?

                            <p key={index} className={content.className} dangerouslySetInnerHTML={{__html: content.description}}/>:

                            content.type == 'image' ?

                                <Image key={index} src={content.imageSrc} srcWebp={content.imageWebpSrc} alt={content.imageAlt} className={content.className} width={content.width} height={content.height}/>:

                                content.type == 'list' ?

                                    <>
                                        {
                                            content.list == 'numbered' ?
                                                <ol className={content.className} key={index}>
                                                    {
                                                        content.description.map((item, index) => (
                                                            <li key={index} dangerouslySetInnerHTML={{__html: item}}/>
                                                        ))
                                                    }
                                                </ol>:
                                                <ul className={content.className} key={index}>
                                                    {
                                                        content.description.map((item, index) => (
                                                            <li key={index}>{item}</li>
                                                        ))
                                                    }
                                                </ul>
                                        }

                                    </>:

                                    content.type == 'video' ?

                                        <video
                                            className={`rounded shadow ${content.className}`}
                                            loading={'lazy'}
                                            preload={'metadata'}
                                            autoPlay={true}
                                            loop={true}
                                            muted={true}
                                            key={index} 
                                            height={content.height}
                                            width={content.width}
                                            poster={content.poster}
                                            playsinline={true}>
                                            <source src={content.mp4} type='video/mp4'/>
                                            <source src={content.mp4_265} type='video/mp4'/>
                                        </video> :

                                        content.type == 'faq' ?

                                            <FAQ content={content} key={index}/>:

                                            content.type == 'pricing' ?

                                                <>
                                                    <Pricing data={PRICING_DATA}/>
                                                    <PricingFeatures data={PRICING_FEATURES} />
                                                </>:

                                                content.type == 'divider' ?

                                                    <hr key={index} />

                                                    : null 
                ))
            }
            {
                showLoomVideo && <div className='loom-video' height={560} style='position: relative; padding-bottom: 66.66666666666666%;'><iframe src='https://www.loom.com/embed/6dcc3a5de259406385608923912d4c0b' frameBorder='0' webkitallowfullscreen mozallowfullscreen allowFullScreen style='position: absolute; top: 0; left: 0; width: 100%; height: 100%;'></iframe></div>
            }
            {children}
        </Col>

    </Section>
}

class ParentsPage extends React.Component {
    constructor(props){
        super(props)
        this.scrollToLearnMore = createRef()
        this.scrollToPricing = createRef()
        this.scrollToMasterYoda = createRef()
        this.scrollToConquer = createRef()
        this.scrollToMemory = createRef()
        this.scrollToElephant = createRef()
    }

    __onClickBenefitsScroll(string) {
        if(string === FOUR_BENEFITS.benefits[0].title) this.scrollToMasterYoda.current.scrollIntoView()
        else if (string === FOUR_BENEFITS.benefits[1].title) this.scrollToConquer.current.scrollIntoView()
        else if (string === FOUR_BENEFITS.benefits[2].title) this.scrollToMemory.current.scrollIntoView()
        else if (string === FOUR_BENEFITS.benefits[3].title) this.scrollToElephant.current.scrollIntoView()
    }

    __onClickSendEvent = (event, values) => {
        window.analytics.track(event, values) 
    }

    __renderHeroSection() { 
        return <div>
            <div className={'d-flex align-items-center heroBg'} data-sal='fade' data-sal-duration='800' data-sal-delay='1200' data-sal-easing='ease-out-quad'>
                <Container className={'heroContainer text-center justify-content-center'}>
                    <Row className='justify-content-center'>
                        <Col className={'heroSection col-12 col-sm-10 mt-3 mt-sm-0'} as='section'>
                            <h1 data-sal='slide-up' data-sal-duration='2000' data-sal-delay='300' data-sal-easing='ease-out-quart' className={'heroTitle'}>{HERO_CONTENT.title}</h1>
                            {
                                HERO_CONTENT.description.map((content, index) => (
                                    <p key={index} data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart' className={'heroDescription'}>{content}</p>
                                ))
                            }
                            <div className='d-flex flex-row flex-column-md align-items-center justify-content-center align-items-sm-start' data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart'>
                                {/* WRAPPER FOR ANIMATION */}
                                <button className='btn btn-primary mb-0 mb-sm-4' onClick={() => {this.scrollToPricing.current.scrollIntoView(); this.__onClickSendEvent(HOME_BUY_NOW_EVENT, HOME_BUY_NOW_EVENT_VALUE)}}>BUY NOW</button>
                                <button className='btn btn-outline-primary ml-3' onClick={() => {this.scrollToPricing.current.scrollIntoView(); this.__onClickSendEvent(HOME_LEARN_MORE_EVENT, HOME_LEARN_MORE_EVENT_VALUE)}}>LEARN MORE</button>
                            </div>
                            <p data-sal='slide-up' data-sal-duration='2000' data-sal-delay='800' data-sal-easing='ease-out-quart' className={'heroDescription pt-3 text-small d-flex flex-wrap justify-content-center  align-items-center '}>
                                <span> &nbsp;<span style={{marginTop: '-4px'}}>{STAR_SVG}</span> 4.81/5 stars (161 reviews)</span>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    }

    __renderBenefits() {
        return <Section background={FOUR_BENEFITS.background} spacing={'py-3'}center refProp={this.scrollToLearnMore}>
            <Col className='col-lg-10 text-center' as='section'  lg={10}>
                <strong className='text-secondary  d-block text-uppercase'>{FOUR_BENEFITS.superTitle}</strong>
                <h2 className={`h1 mb-4 text-center `}>{FOUR_BENEFITS.title}</h2>
                <p>{FOUR_BENEFITS.description}</p>
                <Row className=''>
                    {
                        FOUR_BENEFITS.benefits.map((benefit, index) => (
                            <Col as={'figure'} lg={3} className='col-12 col-lg-6 mb-0' key={index}>
                                {/* {benefit.imageSrc && <Image src={benefit.imageSrc} srcWebp={benefit.imageWebpSrc} className='w-100 fit-contain' width={200} height={200} alt={benefit.title}/>}                               */}
                                <figcaption className='h3 mt-3 px-2 py-3 bg-white rounded cursor-pointer' onClick={() => this.__onClickBenefitsScroll(benefit.title)}>{benefit.title}</figcaption>
                            </Col>
                        ))
                    }
                </Row>
            </Col>
        </Section>
    }

    __renderTestimonials (testimonials, background) {
        return <Section spacing='py-3' background={background ? background : 'bg-light-yellow'}>
            <Col lg={12}>
                <h2 className='h1 text-center mt-0 mt-sm-4' data-sal='slide-up' data-sal-duration='2000' data-sal-delay='300' data-sal-easing='ease-out-quart'>Testimonials</h2>
                <Row className='border rounded py-3 px-3 bg-white d-flex'>
                    {
                        testimonials.map((data,index) => (
                            <Testimonials {...data} key={index} />
                        ))
                    }
                </Row>
            </Col>
        </Section>      
    }

    __renderPricing () {
        return <Section spacing={'py-4'} center refProp={this.scrollToPricing}>
            <Col lg={10} className='col-12 col-lg-10 text-center'  as='section'>
                <h2 className={`h1 mb-4  `}>Pricing</h2>
                <p>{`More effective and cheaper than a private tutor – guaranteed.  If your child completes the courses and doesn't get an A-A*, we'll refund you 100% within 14 days.`}</p>
                <Pricing data={PRICING_DATA}/>
                <PricingFeatures data={PRICING_FEATURES} />
            </Col>
        </Section>
    }

    __renderContactForm () {
        return <Section background={'bg-light-pink'} spacing={'py-3'} center>
            <Col className='col-12 col-sm-10'>
                <h2>Contact Us</h2>
                <p>Have any more questions, message using the form below or email us at hello@quicksense.org</p>
                <Contact />
            </Col>
        </Section>
    }

    render(){
        return <>
            <SEO title={'Parents'}/>
            <Layout type='floating' classes='fw-landing-page qs-direct-page text-center text-sm-left' focusedMode focusedModeURL={'/parents/'}>
                {this.__renderHeroSection()}
                {this.__renderBenefits()}
                <PageSection content={YODA_BENEFIT} refProp={this.scrollToMasterYoda}/>
                <PageSection content={CONQUER_WEAKNESS_BENEFIT} refProp={this.scrollToConquer} />
                <PageSection content={FORGETTING_CURVE_BENEFIT} refProp={this.scrollToMemory}/>
                <PageSection content={ELEPHANT_EATING_BENEFIT} refProp={this.scrollToElephant}/>
                <PageSection content={GET_A_WITHOUT_QUICKSENSE}/>
                {this.__renderTestimonials(RP_TESTIMONIAL)}
                <PageSection content={EVERYTHING_CHILD_NEEDS}/>
                <PageSection content={HOW_IT_WORKS_VIDEO} showLoomVideo/>
                {this.__renderPricing()}
                <PageSection content={TUTORING_COMPARISON}/>
                <PageSection content={SECURE_GRADE}/>
                <PageSection content={PROGRAM_STOP_CHARGE}/>
                <PageSection content={OUR_TEAM}>
                    <div>
                        {
                            KEY_PEOPLE.map((person, index) => (
                                <Col as='figure' className='col-12 text-center rounded' key={index}>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            {person.src && <Image src={person.src} srcWebp={person.srcWebp} className={'rounded shadow mb-3 fit-cover'} alt={person.name} height={88} width={88}/>}
                                        </div>
                                        <div className='text-left ml-3'>
                                            <p className='h3 mb-0'>{person.name}</p>
                                            <p className='h4 mb-0'>{person.title}</p>
                                        </div>
                                    </div>  
                                    <ul className='text-left pl-3'>
                                        {
                                            person.description.map((p, index) => (
                                                <li key={index}><small>{p}</small></li>
                                            ))
                                        }
                                        <li><small><em>{person.qualification}</em></small></li>
                                    </ul>
                                    <hr/>
                                </Col>
                            ))
                        }
                    </div>
                </PageSection>
                {this.__renderTestimonials(PARENT_TESTIMONIALS, 'bg-light-pink')}
                <PageSection content={FOUNDER_MESSAGE}/>
                <PageSection content={FAQ_SECTION} />
                {this.__renderContactForm()}
            </Layout>
        </>
    }
}

export default ParentsPage

Pricing.propTypes = {
    data: PropTypes.array
}

PricingFeatures.propTypes = {
    data: PropTypes.array
}

PageSection.propTypes = {
    content: PropTypes.object,
    showLoomVideo: PropTypes.bool,
    children: PropTypes.node,
    refProp: PropTypes.object
}

FAQ.propTypes = {
    content: PropTypes.object
}