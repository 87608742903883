// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-direct-js": () => import("./../../../src/pages/direct.js" /* webpackChunkName: "component---src-pages-direct-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-guarantee-js": () => import("./../../../src/pages/guarantee.js" /* webpackChunkName: "component---src-pages-guarantee-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parents-js": () => import("./../../../src/pages/parents.js" /* webpackChunkName: "component---src-pages-parents-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-contentful-notes-slug-js": () => import("./../../../src/pages/resources/{contentfulNotes.slug}.js" /* webpackChunkName: "component---src-pages-resources-contentful-notes-slug-js" */),
  "component---src-pages-resources-contentful-topic-category-topic-category-js": () => import("./../../../src/pages/resources/{contentfulTopicCategory.topicCategory}.js" /* webpackChunkName: "component---src-pages-resources-contentful-topic-category-topic-category-js" */),
  "component---src-pages-resources-contentful-topic-topic-name-js": () => import("./../../../src/pages/resources/{contentfulTopic.topicName}.js" /* webpackChunkName: "component---src-pages-resources-contentful-topic-topic-name-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

